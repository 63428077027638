<template>
  <div class="container py-12 md:py-16 lg:py-20 xl:py-24">
    <AuthCard title="Login ">
      <Form @keydown.enter.prevent="handleSubmit" layout="vertical">
        <!-- <p
          class="text-center text-sm mb-6 text-text-light"
        >Login or Create Account for Your Community</p>-->
        <div class="flex flex-col gap-4">
          <CustumInput
            name="email"
            type="email"
            :defaultData="defaultData ? defaultData.email : null"
            label="Email Address"
            placeholder="john.doe@domain.com"
            :rules="{
              required: true,
              email: true,
              min: 3,
            }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />

          <CustumInputPassword
            name="password"
            :defaultData="defaultData ? defaultData.password : null"
            label="Password"
            placeholder="password ..."
            :rules="{
              required: true,
              min: 6,
            }"
            :isTouched="isAllTouched"
            @setInput="setInput"
          />
          <div class="flex items-center justify-between">
            <Checkbox>Remember me</Checkbox>
          </div>
        </div>

        <div class="mt-6">
          <Button
            type="primary"
            htmlType="submit"
            class="rounded"
            size="large"
            block
            :loading="isLoading"
            @click.prevent="handleSubmit"
            >Login</Button
          >
        </div>
      </Form>
    </AuthCard>

    <Modal
      :visible="visible"
      :footer="null"
      :destroyOnClose="true"
      wrap-class-name="empty-modal"
      @ok="handleModalClose"
      @cancel="handleModalClose"
    >
      <AlertModal :warningMessage="warningMessage" @close="handleModalClose" />
    </Modal>
  </div>
</template>

<script>
import validation from '@/services/validation';
import { message, Checkbox, Button, Modal, Form } from 'ant-design-vue';
import CustumInput from '@/components/shared/form/CustumInput.vue';
import CustumInputPassword from '@/components/shared/form/CustumInputPassword.vue';
import authApi from '@/services/apis/auth.api';
import handleResErrors from '@/utils/handleResErrors';
import AuthCard from './AuthCard.vue';
import AlertModal from './AlertModal.vue';
import { saveToken } from '../../services/jwt.service';

export default {
  components: {
    AuthCard,
    CustumInput,
    CustumInputPassword,
    Checkbox,
    Button,
    AlertModal,
    Modal,
    Form,
  },
  props: {
    defaultData: Object,
  },
  data() {
    return {
      isAllTouched: false,
      isLoading: false,
      formData: {},
      visible: false,
      warningMessage: '',
    };
  },
  computed: {
  },
  methods: {
    handleModalClose() {
      this.visible = false;
      this.warningMessage = '';
    },
    setInput(vals) {
      this.formData = { ...this.formData, ...vals };
    },
    async handleSubmit(e) {
      e.target.blur();

      this.isAllTouched = true;
      this.isLoading = true;
      const validetionData = validation.validateAllData(this.formData);

      if (!validetionData.isValid) {
        return;
      }

      try {
        const res = await authApi.postLogin(validetionData.data);
        // console.log('DDDDDDDDDDDDD', res.data);
        if (res.data) {
          if (res.data.user) {
            message.success('logged in successfully');
            saveToken(res.data.accessToken);
            this.$router.push('/dashboard');
          } else {
            this.visible = true;
            // eslint-disable-next-line operator-linebreak
            this.warningMessage =
              'We are sorry, your account is not active yet. Please contact our Customer Service.';

            message.error(
              'We are sorry, your account is not active yet. Please contact our Customer Service.',
            );
          }
        } else {
          this.isLoading = false;
          message.error(
            'We are sorry, your account is not active yet. Please contact our Customer Service.',
          );
        }
      } catch (error) {
        message.error(handleResErrors(error));

        this.isLoading = false;
      }
    },
  },
};
</script>
