import { BOOK } from '../types';

export default {
  state: {
    bookQuery: null,
  },
  getters: {},
  mutations: {
    [BOOK.SET_BOOK_QUERY](state, payload) {
      state.bookQuery = payload;
    },
  },
  actions: {},
};
