<template>
  <CustumLabel
    :name="name"
    :label="label"
    :error="touched && error"
    :required="rules && rules.required"
    :hideErrors="hideErrors"
  >
    <Textarea
      class="w-full"
      :value="value"
      :placeholder="placeholder"
      @change="handleChange"
      @blur="handleBlur"
      :allowClear="true"
      :rows="rows"
    />
  </CustumLabel>
</template>

<script>
import validation from '@/services/validation';
import { Textarea } from 'ant-design-vue';
import CustumLabel from './CustumLabel.vue';

export default {
  components: { CustumLabel, Textarea },
  props: {
    name: String,
    label: String,
    placeholder: String,
    hideErrors: Boolean,
    defaultData: String,
    rules: {
      type: Object,
      default: null,
    },
    isTouched: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      value: null,
      error: null,
      touched: false,
      isValid: false,
    };
  },
  created() {
    if (this.defaultData) {
      this.value = this.defaultData;
      this.touched = true;
    }
    this.handleValidate();
    this.setInputValues();
  },
  watch: {
    isTouched(val) {
      if (val) {
        this.touched = true;
      }
    },
  },
  methods: {
    handleBlur() {
      this.touched = true;
      this.handleValidate();
      this.setInputValues();
    },
    handleChange(e) {
      const val = e.target.value || null;
      this.error = null;
      this.value = val;
    },
    handleValidate() {
      const { error, isValid } = validation.validateRules(this.rules, this.value);
      this.error = error;
      this.isValid = isValid;
    },
    setInputValues() {
      const isRequired = this.rules && this.rules.required;
      if (!isRequired && !this.value) {
        return;
      }
      this.$emit('setInput', {
        [this.name]: {
          name: this.name,
          value: this.value,
          error: this.error,
          touched: this.touched,
          isValid: this.isValid,
        },
      });
    },
  },
};
</script>
