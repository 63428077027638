import authModule from './auth.module';
import layoutModule from './layout.module';
import usersModule from './users.module';
import rolesModule from './roles.module';
import dashModule from './dash.module';
import categoryModule from './category.module';
import testimonialModule from './testimonial.module';
import faqModule from './faq.module';
import partnerModule from './partner.module';
import blogModule from './blog.module';
import bookModule from './book.module';
import libraryModule from './library.module';
import universityModule from './university.module';
import sliderModule from './slider.module';

const modules = {
  auth: authModule,
  layout: layoutModule,
  users: usersModule,
  roles: rolesModule,
  dash: dashModule,
  category: categoryModule,
  testimonial: testimonialModule,
  faq: faqModule,
  partner: partnerModule,
  book: bookModule,
  blog: blogModule,
  library: libraryModule,
  university: universityModule,
  slider: sliderModule,

};

export default modules;
