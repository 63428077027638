<template>
  <section class="mt-6 lg:mt-8">
    <div class="container">
      <StaticticsIndex :statisticsData="currentData?.statistics" />

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 my-10 gap-5 lg:gap-7">
      </div>
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-7">
        <!-- box - 1  -->
        <div  class="w-full main-card px-3 p-3">
          <h3 class="text-lg font-bold text-center mb-4">SUBSCRIBES</h3>
          <div>Maillist</div>
           <maillistTable :currentMaillist="currentData?.mail_list?.data" />
        </div>

        <!-- box - 2  -->
        <div v-if="currentData?.logs" class="w-full main-card px-3 p-3">
          <h3 class="text-lg font-bold text-center mb-4">Logs</h3>
          <div>Log</div>
          <logTimeLine :currentLogs="currentData?.logs?.data" />
        </div>

        <div v-if="currentData?.visitor_messages" class="lg:col-span-2 w-full main-card px-3 p-3">
          <h3 class="text-lg font-bold text-center mb-4">Messages</h3>
          <div>Current Messages</div>
          <MessageTable :currentMessages="currentData?.visitor_messages?.data" />
        </div>

      </div>

      <DashStatsQuery />
    </div>
  </section>
</template>

<script>
import DashStatsQuery from '@/services/queries/dash/DashStatsQuery.vue';
import logTimeLine from './blocks/logTimeLine.vue';
import maillistTable from './blocks/maillistTable.vue';
import StaticticsIndex from './blocks/StaticticsIndex.vue';
import MessageTable from './blocks/MessageTable.vue';

export default {
  components: {
    DashStatsQuery,
    logTimeLine,
    StaticticsIndex,
    maillistTable,
    MessageTable,
  },
  data() {
    return {
      pageNum: 1,
      perPage: 10,
    };
  },
  watch: {},
  computed: {
    dashQuery() {
      return this.$store.state.dash.dashStatsQuery;
    },
    currentData() {
      const q = this.$store.state.dash.dashStatsQuery;
      if (!q || q.isLoading || q.isError) {
        return [];
      }
      return q.data?.data;
    },
  },
  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped></style>
