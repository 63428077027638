const DASH = {
  SET_DASH_STATS_QUERY: 'SET_DASH_STATS_QUERY',
  SET_MESSAGE_QUERY: 'SET_MESSAGE_QUERY',
  SET_LOGS_QUERY: 'SET_LOGS_QUERY',
  SET_MAILLIST_QUERY: 'SET_MAILLIST_QUERY',
  SET_SETTINGS_QUERY: 'SET_SETTINGS_QUERY',
  SET_DELETED_MESSAGE_QUERY: 'SET_DELETED_MESSAGE_QUERY',
  SET_ABOUTUS_QUERY: 'SET_ABOUTUS_QUERY',
};

export default DASH;
