import { LIBRARY } from '../types';

export default {
  state: {
    blockQuery: null,
    smartreaderQuery: null,
    aiQuery: null,
    educationQuery: null,
  },
  getters: {},
  mutations: {
    [LIBRARY.SET_BLOCK_QUERY](state, payload) {
      state.blockQuery = payload;
    },

    [LIBRARY.SET_READER_QUERY](state, payload) {
      state.smartreaderQuery = payload;
    },

    [LIBRARY.SET_AI_QUERY](state, payload) {
      state.aiQuery = payload;
    },

    [LIBRARY.SET_EDUCATION_QUERY](state, payload) {
      state.educationQuery = payload;
    },
  },
  actions: {},
};
